import React, { Component } from "react";
import { Link,useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Col, Row, Form} from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import FrameDisplayRow from './FrameDisplayRow';
import FrameInputRow from './FrameInputRow';
import OutputLinkRow from './OutputLinkRow';



const strBEURL = 'clammytest.assertions.ca'   // back end url for prod  BE
// this will use BE get model /amodel/user-model/  which will expect the cookie to be in place

const showFeedback = (feedback => {
    if (document.getElementById("messagePlace") ){
      document.getElementById("messagePlace").innerHTML = feedback
    }
})


export default class UseCubicle extends Component {
    constructor(props) {
        super(props)
        // State
        this.state = {
            FullCubicle: '',   // the full cubicle object 
            AssnName: '',
            AuthName: '',
            Specification: '',
            DBID: '',
            InputFrames: [],
            BaseFrame: {typeName: 'huh'},
        }

        this.updateAttrProto = this.updateAttrProto.bind(this);
        this.updateAttributeProtos = this.updateAttributeProtos.bind(this);
        this.OutputsList = this.OutputsList.bind(this);
        
    }

    componentDidMount() {
        this.fetchCubicle()  // will cause Rerender if state changes
    }


    fetchCubicle() {

        let strParam = "?dbid=" + this.props.match.params.dbid
        showFeedback('... ' )
        axios.get('https://' +  strBEURL + '/cubicle/full-cubicle/' + strParam) // this gets the full cubicle object
        .then(res => {
            //showFeedback(JSON.stringify(res.data))  // just to check  base.assnTitle.Name
            let inputCopy = JSON.parse(JSON.stringify(res.data))
            this.setState({
                FullCubicle: inputCopy,
                AssnName: inputCopy.base.assnTitle.SpecID + '  - ' + inputCopy.base.assnTitle.Name,
                AuthName: inputCopy.base.assnTitle.AuthName,
                InputFrames: inputCopy.inputs,
                Specification: inputCopy.base.assnTitle.Specification,
                DBID: inputCopy.base.assnTitle.AutoID,
                BaseFrame: inputCopy.base.assnTypes[0],
            });
        })
        .catch((error) => {
            showFeedback('Error fetching Cubicle: ' + error)
        })

    }



    updateAttrProto(pair)  {  // this function is passed down to the row to allow it to update the cubicle ( which then can emit)
        
        this.fetchCubicle()
        
        /* NEEDED  
        // for now lets just see this register in feedback
        //showFeedback('Got summt from summer, innit: ' + JSON.stringify(pair))
        // if this works then it has to cycle the attributes to change the right one
            // try to change the object  rather thatn recreate  - no bad practice to mutate
            console.log(JSON.stringify(this.state))
            let newAttributes = []
            let newAttr
            this.state.BaseFrame.attributes.forEach( attr =>{
                newAttr=  JSON.parse(JSON.stringify(attr))
                if (newAttr.ATTRGUID === pair.guid) {
                   
                    newAttr.ATTRPROTYPE = pair.proto  // still mutating
                }   // this SHOULD change the state all the way down - then to update protos just send the attributes
                newAttributes.push(newAttr)// now add this to a new attributes array
            })
            // this is so ugly
            let typeName = this.state.BaseFrame.typeName
            let strTypeGUID = this.state.BaseFrame.strTypeGUID
           
            this.setState({
                BaseFrame: {
                    typeName: typeName,
                    strTypeGUID: strTypeGUID,
                    attributes: newAttributes,
                }

              });
NEEDED*/
             
    }

    updateAttributeProtos()  {   // Used to remember the frames values  // this will cycle the attributes push update promises and then do promise all -- then report
        let arrPromUpdates = []
        let strParam
        this.state.BaseFrame.attributes.forEach( attr => {
            strParam = '?PrototypeValue='+ attr.ATTRPROTYPE+'&strAttributeGUID=' + attr.ATTRGUID
            arrPromUpdates.push(     // the endpoint takes the vaoues to be updated so only ptoto is needed
                axios.get('https://clammycubicle.assertions.ca/attribute/update-attribute' + strParam)
            )
        })
        Promise.all(arrPromUpdates)
        .then((values) => {
            showFeedback(JSON.stringify(values))
            console.log(values);
        })
        .catch(error => {
               console.log(`::Error::<br> ${error}`)
               showFeedback(JSON.stringify(error))
        })
    }


    FrameRows() {
        return this.state.InputFrames.map((frame, i) => {
         
         return <FrameDisplayRow dbid={frame.assnTitle.AutoID} authName={frame.assnTitle.AuthName} title={frame.assnTitle.SpecID + ' -- ' + frame.assnTitle.Name} attrs={frame.assnTypes[0].attributes} key={i} />;  //This does each of the inputs  // there will be only one type per frame for now   -- thi FrameDisplayRow will be passed an arg assnTypes (which will be array of assertoins accessed by attributes an array)
        });
      }


      OutputsList(outputs) {

        //return <OutputLinkRow AutoID={'wtf'}  />;
       //return outputs.map((obj) => { 
        //     return <OutputLinkRow AutoID={obj.AutoID}  />;
       //    });
       let arrOutputs = [{AutoID: 'hey'},{AutoID:'huh'}]
      if (outputs !== undefined) {
       
        arrOutputs = outputs
    }
   
           return arrOutputs.map((title) => { 
            return <OutputLinkRow title={title}  />;
          });


        }

render() {
    //var inputConnections = this.state.InputConnections
    let showAttrs = []
    if (this.state.BaseFrame !== undefined )
        {showAttrs = this.state.BaseFrame.attributes
        let dirtyOutputs = this.state.FullCubicle.outputs
    
   // let cleanOutputs = JSON.parse(JSON.stringify(dirtyOutputs))
    if (!showAttrs) { showAttrs=[]}
    return (
        <div className="form-wrapper">
 
        <Row>
            <Col> 
            <p>Simulated Screen for: <font color="green"> <b>{this.state.AuthName}</b></font> </p>
                <p><font size="5">{this.state.AssnName  } </font>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <Link
                    className="edit-link" //path={"/edit-assertion/:id"}     //    className="edit-link" path={"assertion/:id"}
                    to={'/edit-assertion/' + this.state.DBID}
                >
                    Edit
                </Link></p> 
                <p><b>Input</b> information from the following assertions:</p>
                {this.FrameRows()}
            </Col>
            <Col>
            <p align="right">
              <Link
                className="nav-link" path={"/:KNID"}     //    className="edit-link" path={"assertion/:id"}
                to={'/explain/KN45'}   // hardcoded so when it comes off the Nav bar it has a place to open - ie intro for all  ---  when hitting explain from a particular panel the buton will pass the panel id
                >
                Explain&#62;
              </Link>
              </p>
                <p></p>
                <Row> <span class="square border border-4"><Col>
                    <p><b>Guidance for determining this Assertion:  </b></p> 
                    <p>{this.state.Specification} </p> 
                </Col></span></Row>
                <p></p>
                <Row> <span class="square border border-3"><Col>
                    {showAttrs.map((attr,i) => {
                        return <FrameInputRow  key={i} handleAttrChange={this.updateAttrProto} attr={attr} />;  //This does each of the inputs  // there will be only one type per frame for now   -- thi FrameDisplayRow will be passed an arg assnTypes (which will be array of assertoins accessed by attributes an array)
                    })}
                    <p></p>
                </Col></span></Row>
                <p></p>
                <Button  onClick={this.updateAttributeProtos} size="sm" variant="primary" >
                    Emit Assertion
                </Button>
                <p>This frame sent to: </p>
                {this.OutputsList(dirtyOutputs)}
                <div id="messagePlace" class="alert alert-primary" role="alert">
                    ...
                </div>
            </Col>
        </Row>
    
        </div>  
    )

}
    else{ return 'Loading'}
}
}
