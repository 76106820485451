import React, { Component } from 'react'


export default class AttrDisplayRow extends Component {
  constructor(props) {
    super(props)
  }

  render() {
   
        {/**    map the attributes */}

        return (
          <div > <table width="100%" bgcolor="#DDEEFF"><tr><td>
          <font size="4">{this.props.attr.ATTRNAME}</font>    ---   <font size="2" color="grey">{this.props.attr.ATTRDESC}</font> 
           </td></tr></table>
            <p><font size="3" color="green">{this.props.attr.ATTRPROTYPE}</font></p>
          </div>
      )



   

        
      





    
  }
}