import React, { Component } from 'react'



export default class DiagramOptionRow extends Component {
  constructor(props) {
    super(props)
  }

  render() {
   
        {/**  comment   */}
    return <option value={this.props.obj.strGUID }>{ this.props.obj.Name  + ' (' + this.props.obj.elemCount + ' elements)'}</option>

    
  }
}