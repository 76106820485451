import React, { Component } from "react";
//import Form from 'react-bootstrap/Form'
import { Col, Row, Form } from "react-bootstrap";

import Button from 'react-bootstrap/Button';
import axios from 'axios';

const strBEURL = 'clammytest.assertions.ca'   // back end url for prod  BE
//const strBEURL = 'localhost:9000'
//    ' +  strBEURL + '

//  I think this is DEPRECATED
export default class CreateAssertion extends Component {

  constructor(props) {
    super(props)

    // Setting up functions
   
    

    this.onChangeAssertionName = this.onChangeAssertionName.bind(this);
    this.onChangeAssertionSpecID  = this.onChangeAssertionSpecID.bind(this);
    this.onChangeAssertionDesc = this.onChangeAssertionDesc.bind(this);
    this.onChangeAssertionActivityType = this.onChangeAssertionActivityType.bind(this);
    this.onChangeAssertionAutomatic = this.onChangeAssertionAutomatic.bind(this);
    
    this.onChangeAssertionSpecification = this.onChangeAssertionSpecification.bind(this);
    this.onChangeAssertionCurrencyNote = this.onChangeAssertionCurrencyNote.bind(this);
    this.onChangeAssertionAttainmentNote = this.onChangeAssertionAttainmentNote.bind(this);
    this.onChangeAssertionSizeNote = this.onChangeAssertionSizeNote.bind(this);
    this.onChangeAssertionStatus = this.onChangeAssertionStatus.bind(this);
    
    this.onChangeAssertionModelNote = this.onChangeAssertionModelNote.bind(this);
    this.onChangeAssertionImplementationNote = this.onChangeAssertionImplementationNote.bind(this);
    this.onChangeAssertionOutputNote = this.onChangeAssertionOutputNote.bind(this);
    this.onChangeAssertionModelLevel = this.onChangeAssertionModelLevel.bind(this);
    this.onChangeAssertionEffortHours = this.onChangeAssertionEffortHours.bind(this);
    
    this.onChangeAssertionFinancial = this.onChangeAssertionFinancial.bind(this);
    this.onChangeAssertionPersonal = this.onChangeAssertionPersonal.bind(this);
    this.onChangeAssertionRecord = this.onChangeAssertionRecord.bind(this);
    this.onChangeAssertionScript = this.onChangeAssertionScript.bind(this);
    this.onChangeAssertionDisplayText = this.onChangeAssertionDisplayText.bind(this);
    
    this.onChangeAssertionIsAttainment = this.onChangeAssertionIsAttainment.bind(this);
    this.onChangeAssertionLastModifiedDate = this.onChangeAssertionLastModifiedDate.bind(this);
    this.onChangeAssertionCreatedDate = this.onChangeAssertionCreatedDate.bind(this);
    this.onChangeAssertionstrAuthGUID = this.onChangeAssertionstrAuthGUID.bind(this);
    this.onChangeAssertionstrGUID = this.onChangeAssertionstrGUID.bind(this);
    
    this.onSubmit = this.onSubmit.bind(this);
    
    
    



    // Setting up state
   
this.state = {
  title: 'assertion',
  Name: '',
  SpecID: '', 
  Desc: '',
  ActivityType: '',
  Automaticfalse: '',
  Specification: '',
  CurrencyNote: '',
  AttainmentNote: '',
  SizeNote: '',
  Status: '',
  ModelNote: '',
  ImplementationNot: '',
  OutputNote: '',
  ModelLevel: '',
  EffortHours: '',
  Financial: '',
  Personal: '',
  Record: '',
  Script: '',
  DisplayText: '',
  IsAttainment: '',
  LastModifiedDate: '',
  CreatedDate: '',
  strAuthGUID: '',
  strGUID: ''
  }
  
  }
//--------------------------------------------------
onChangeAssertionName(e) {
  this.setState({ Name: e.target.value })};
onChangeAssertionSpecID (e) {
  this.setState({ SpecID : e.target.value })};
onChangeAssertionDesc(e) {
  this.setState({ Desc: e.target.value })};
onChangeAssertionActivityType(e) {
  this.setState({ ActivityType: e.target.value })};
onChangeAssertionAutomatic(e) {
  this.setState({ Automatic: e.target.value })};

onChangeAssertionSpecification(e) {
  this.setState({ Specification: e.target.value })};
onChangeAssertionCurrencyNote (e) {
  this.setState({ CurrencyNote : e.target.value })};
onChangeAssertionAttainmentNote(e) {
  this.setState({ AttainmentNote: e.target.value })};
onChangeAssertionSizeNote(e) {
  this.setState({ SizeNote: e.target.value })};
onChangeAssertionStatus (e) {
  this.setState({ Status : e.target.value })};

onChangeAssertionModelNote (e) {
  this.setState({ ModelNote : e.target.value })};
onChangeAssertionImplementationNote(e) {
  this.setState({ ImplementationNote: e.target.value })};
onChangeAssertionOutputNote(e) {
  this.setState({ OutputNote: e.target.value })};
onChangeAssertionModelLevel(e) {
  this.setState({ ModelLevel: e.target.value })};
onChangeAssertionEffortHours(e) {
  this.setState({ EffortHours: e.target.value })};

onChangeAssertionFinancial(e) {
  this.setState({ Financial: e.target.value })};
onChangeAssertionPersonal(e) {
  this.setState({ Personal: e.target.value })};
onChangeAssertionRecord (e) {
  this.setState({ Record : e.target.value })};
onChangeAssertionScript (e) {
  this.setState({ Script : e.target.value })};
onChangeAssertionDisplayText (e) {
  this.setState({ DisplayText : e.target.value })};

onChangeAssertionIsAttainment(e) {
  this.setState({ IsAttainment: e.target.value })};
onChangeAssertionLastModifiedDate(e) {
  this.setState({ LastModifiedDate: e.target.value })};
onChangeAssertionCreatedDate(e) {
  this.setState({ CreatedDate: e.target.value })};
onChangeAssertionstrAuthGUID(e) {
  this.setState({ strAuthGUID: e.target.value })};
onChangeAssertionstrGUID(e) {
  this.setState({ strGUID: e.target.value })};
//------------------------------------------------
  onSubmit(e) {
    e.preventDefault()

    const assertionObject = {
      //name: this.state.name,
      //specid: this.state.specid,
      title: this.state.title,

      Name: this.state.Name,
      SpecID: this.state.SpecID ,
      Desc: this.state.Desc,
      ActivityType: this.state.ActivityType,
      Automatic: this.state.Automatic,        
      Specification: this.state.Specification,
      CurrencyNote : this.state.CurrencyNote ,
      AttainmentNote: this.state.AttainmentNote,        
      SizeNote: this.state.SizeNote,
      Status : this.state.Status ,
      ModelNote : this.state.ModelNote ,
      ImplementationNote: this.state.ImplementationNote,
      OutputNote: this.state.OutputNote,
      ModelLevel: this.state.ModelLevel,
      EffortHours: this.state.EffortHours,
      Financial: this.state.Financial,
      Personal: this.state.Personal,
      Record : this.state.Record ,
      Script : this.state.Script ,
      DisplayText : this.state.DisplayText ,
      IsAttainment: this.state.IsAttainment,
      LastModifiedDate: this.state.LastModifiedDate,    
      CreatedDate: this.state.CreatedDate,
      strAuthGUID: this.state.strAuthGUID,
      strGUID: this.state.strGUID

    };
    console.debug('Sending:  ' + assertionObject);
    axios.post('http://' +  strBEURL + '/assertions/create-assertion', assertionObject)
      .then(res => console.log(res.data));

    //this.setState({ name: '', specid: '', title: '' })

    this.setState({ Name: '', SpecID : '', Desc: '', ActivityType: '', Automatic: '', Specification: '', CurrencyNote : '', AttainmentNote: '', SizeNote: '', Status : 
    '', ModelNote : '', ImplementationNote: '', OutputNote: '', ModelLevel: '', EffortHours: '', Financial: '', Personal: '', Record : '', Script : '', DisplayText : '', IsAttainment: '', LastModifiedDate: '', CreatedDate: '', strAuthGUID: '', strGUID: '',  })
  }
// Form.Group etc are Bootstrap stuff
  render() {
    return (<div className="form-wrapper">
<Form onSubmit={this.onSubmit}>
  <Row>
    <span class="square border border-4">
    <Col ><b>R1C1</b>
   

    <Form.Group  controlId="Name" >
          <Form.Label>Name 
                <Form.Control type="text" value={this.state.Name } onChange={this.onChangeAssertionName } />
          </Form.Label>
      </Form.Group>
      <Form.Group controlId="SpecID" >
          <Form.Label>SpecID </Form.Label>
                <Form.Control type="text" value={this.state.SpecID } onChange={this.onChangeAssertionSpecID } />
      </Form.Group>
      <Form.Group controlId="Desc">
          <Form.Label>Desc</Form.Label>
                <Col>
                <Form.Control type="text" value={this.state.Desc} onChange={this.onChangeAssertionDesc} />
                </Col>
      </Form.Group>

      <Form.Group controlId="Automatic">
          <Form.Label>Automatic</Form.Label>
                <Form.Control type="text" value={this.state.Automatic} onChange={this.onChangeAssertionAutomatic} />
      </Form.Group>
    
    </Col>
    </span>
    <Col>R1C2
    <Form.Group controlId="OutputNote">
          <Form.Label>OutputNote</Form.Label>
                <Form.Control type="text" value={this.state.OutputNote} onChange={this.onChangeAssertionOutputNote} />
      </Form.Group>
  
    </Col>
  </Row>
  <Row>
    <Col>R2C1
    <Form.Group controlId="ActivityType">
          <Form.Label>ActivityType</Form.Label>
                <Form.Control type="text" value={this.state.ActivityType} onChange={this.onChangeAssertionActivityType} />
      </Form.Group>
      <Form.Group controlId="CurrencyNote" >
          <Form.Label>CurrencyNote </Form.Label>
                <Form.Control type="text" value={this.state.CurrencyNote } onChange={this.onChangeAssertionCurrencyNote } />
      </Form.Group>
      <Form.Group controlId="AttainmentNote">
          <Form.Label>AttainmentNote</Form.Label>
                <Form.Control type="text" value={this.state.AttainmentNote} onChange={this.onChangeAssertionAttainmentNote} />
      </Form.Group>
      <Form.Group controlId="SizeNote">
          <Form.Label>SizeNote</Form.Label>
                <Form.Control type="text" value={this.state.SizeNote} onChange={this.onChangeAssertionSizeNote} />
      </Form.Group>
      <Form.Group controlId="ModelLevel">
          <Form.Label>ModelLevel</Form.Label>
                <Form.Control type="text" value={this.state.ModelLevel} onChange={this.onChangeAssertionModelLevel} />
      </Form.Group>
      <Form.Group controlId="EffortHours">
          <Form.Label>EffortHours</Form.Label>
                <Form.Control type="text" value={this.state.EffortHours} onChange={this.onChangeAssertionEffortHours} />
      </Form.Group>
      Financial
      <Form.Group controlId="Personal">
          <Form.Label>Personal</Form.Label>
                <Form.Control type="text" value={this.state.Personal} onChange={this.onChangeAssertionPersonal} />
      </Form.Group>
      <Form.Group controlId="Record" >
          <Form.Label>Record </Form.Label>
                <Form.Control type="text" value={this.state.Record } onChange={this.onChangeAssertionRecord } />
      </Form.Group> 
    </Col>
    <Col>R2C2
    <Form.Group controlId="ModelNote" >
          <Form.Label>ModelNote </Form.Label>
                <Form.Control type="text" value={this.state.ModelNote } onChange={this.onChangeAssertionModelNote } />
      </Form.Group> 
      <Form.Group controlId="ImplementationNote">
          <Form.Label>ImplementationNote</Form.Label>
                <Form.Control type="text" value={this.state.ImplementationNote} onChange={this.onChangeAssertionImplementationNote} />
      </Form.Group>
      <Form.Group controlId="LastModifiedDate">
          <Form.Label>LastModifiedDate</Form.Label>
                <Form.Control type="text" value={this.state.LastModifiedDate} onChange={this.onChangeAssertionLastModifiedDate} />
      </Form.Group>
      <Form.Group controlId="CreatedDate">
          <Form.Label>CreatedDate</Form.Label>
                <Form.Control type="text" value={this.state.CreatedDate} onChange={this.onChangeAssertionCreatedDate} />
      </Form.Group>

      <Form.Group controlId="Status" >
          <Form.Label>Status </Form.Label>
                <Form.Control type="text" value={this.state.Status } onChange={this.onChangeAssertionStatus } />
      </Form.Group> 
      <Form.Group controlId="Script" >
          <Form.Label>Script </Form.Label>
                <Form.Control type="text" value={this.state.Script } onChange={this.onChangeAssertionScript } />
      </Form.Group> 
      <Form.Group controlId="DisplayText" >
          <Form.Label>DisplayText </Form.Label>
                <Form.Control type="text" value={this.state.DisplayText } onChange={this.onChangeAssertionDisplayText } />
      </Form.Group> 
      <Form.Group controlId="IsAttainment">
          <Form.Label>IsAttainment</Form.Label>
                <Form.Control type="text" value={this.state.IsAttainment} onChange={this.onChangeAssertionIsAttainment} />
      </Form.Group>
    </Col>
  </Row>
  <Button variant="danger" size="lg" block="block" type="submit" className="mt-4">
          Create Assertion
        </Button>
</Form>


    </div>);
  }
}