import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Button from 'react-bootstrap/Button'

//  a separate component to be part of the Edit assertion to work for showing, editing, adding,deleting input connections
// nice to have is jump to input spec andmaybe output 
// This is just one input connection


export default class FullConnectionDetail extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
     <p><b>Input Information from:</b> <br />
  
     {this.props.obj.SourceSpecID} - 
     {this.props.obj.SourceSpecName} <br />
     <i>{this.props.obj.SourceOutputNote}</i> <br />
     {this.props.obj.PushPull}  via: {this.props.obj.TransmissionType} <br />
     <i>{this.props.obj.TransmissionNote}</i> <br />
        </p>
    )
  }
}
