import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Col, Row, Form} from "react-bootstrap";
import Button from 'react-bootstrap/Button';
const strBEURL = 'clammytest.assertions.ca'   // back end url for prod  BE
// this will use BE get model /amodel/user-model/  which will expect the cookie to be in place

export default class Login extends Component {
constructor(props) {
    super(props)
  
    this.onChangeUserModel = this.onChangeUserModel.bind(this);
    this.onChangeUMPassword = this.onChangeUMPassword.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.showMessage = this.showMessage.bind(this)
    // State
    this.state = {
        UserModel: '',
        UMPassword : ''
    }
  }

  // below does not work
showMessage(message) {
    if (document.getElementById("messagePlace") ){
        document.getElementById("messagePlace").innerHTML = {message} ;
    }
}
  componentDidMount() {
    this.setState({
        UserModel: '',
        UMPassword: '' ,
      })
//  now it should be possible to access the model object   
  };
  
  onChangeUserModel(e) {this.setState({ UserModel: e.target.value })}   // this does not appear to happen
  onChangeUMPassword(e) {this.setState({ UMPassword: e.target.value })}

onSubmit(e) {      // this is where the axios login is called
    let strMon = 'MON:\n'
    e.preventDefault()
    //let strParam = {UserID: 'User3' , UserPassword:  'P@55w0rD'}       // kludge for now to test
    let strParam =  'UMID=' + this.state.UserModel + '&UserPassword=' +  this.state.UMPassword   // state does not seem to be populated here
   
    //axios.post('http://' +  strBEURL + '/amodel/login/?UserID=User3&UserPassword=P@55w0rD'  )
      console.log('https://' +  strBEURL + '/user/login/?'  + strParam)
    axios.post('https://' +  strBEURL + '/user/login/?'  + strParam)    ///changes to htttps 230907 to make secure??
    .then((res) => {
// must check status here 
        console.log('AfterPost' + res)
        if (res.data.loginStatus === "Logged in")
        {
            strMon += 'starting to set USER_MODEL_TOKEN to:' + res.data.token
            window.localStorage.setItem('USER_MODEL_TOKEN', res.data.token);  // does not show in chrome dev
            strMon += 'Set USER_MODEL_TOKEN to:' + window.localStorage.getItem('USER_MODEL_TOKEN')

            if (document.getElementById("messagePlace") ){
                document.getElementById("messagePlace").innerHTML = 'Logged In for:  '+ this.state.UserModel  + ' !'
            }
       }  else {  // it failed
        if (document.getElementById("messagePlace") ){
            if(res.data.loginStatus) {
                document.getElementById("messagePlace").innerHTML = 'login status: ' + res.data.loginStatus
            }else {
                document.getElementById("messagePlace").innerHTML = 'data: ' + res.data
            }
        }
      //console.log(res.data)
      //console.log('Login done')
        }
    })
    .catch(function (error) {
        if (document.getElementById("messagePlace") ){
            document.getElementById("messagePlace").innerHTML = 'Failed to Login. \n Possible Cross Origin Issue, or 503 code error. \n' + JSON.stringify(error) + '\n' + strMon;
        }
    })
}

render() {
  
    return (<div className="form-wrapper">
        <p align="right">
            <Link
            className="nav-link" path={"/:KNID"}     //    className="edit-link" path={"assertion/:id"}
            to={'/explain/' + 'KN243'}   // hardcoded so when it comes off the Nav bar it has a place to open - ie intro for all  ---  when hitting explain from a particular panel the buton will pass the panel id
            >
            Explain&#62;
            </Link>
        </p>
        <Form  class="form-inline" onSubmit={this.onSubmit}> {/*  class="form-inline"    does not work */}
        <Row>
            <span class="square border border-4">
                <Col >
                <b>Model</b>  
                    <Form.Group  controlId="UserModel" class="list-inline  float-right  w-25">
                        <Form.Label   class="float-right" >UserModel
                                <Form.Control type="text" value={this.state.UserModel } onChange={this.onChangeUserModel } />
                        </Form.Label>
                    </Form.Group>
                   <Form.Group controlId="Password" class="list-inline  float-right w-25">
                        <Form.Label>User Model Password </Form.Label>
                                <Form.Control type="password" value={this.state.UMPassword } onChange={this.onChangeUMPassword } />
                    </Form.Group>
                    Ask Richard Lay at context@islandnet.com to set up a user model for you.
                </Col>
            </span>
        </Row>
        <Button variant="danger" size="lg" block="block" type="submit" className="mt-4">
                Login
        </Button>
        <div id="messagePlace" class="alert alert-primary" role="alert">
                    login...
        </div>
        </Form>
        </div>
    )
}
}  // end Login Component