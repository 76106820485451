import React, { Component } from 'react'


export default class AuthOptionsRow extends Component {
  constructor(props) {
    super(props)
  }

  render() {
   
        {/**    If we can return this repeat then part way there */}
    return <option value={this.props.obj.strGuid }>{ this.props.obj.Name}</option>

    
  }
}
