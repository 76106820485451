import React, { Component } from "react";
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import AssertionTableRow from './AssertionTableRow';
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom';

const strBEURL = 'clammytest.assertions.ca'   // back end url for prod  BE
//const strBEURL = 'localhost:9000'
//    ' +  strBEURL + '

export default class AssertionList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      assertions: [],
      status: 'OK'
    };

    this.addAssertion = this.addAssertion.bind(this)
  }

  componentDidMount() {
    this.getAssnList()
  }
  componentDidUpdate() {
    //this.getAssnList()   //bad news this causes inf loop 
  }




  getAssnList(){

    let strParams = "?token=" + localStorage.getItem('USER_MODEL_TOKEN')
    console.log(strParams)
    axios.get('https://' +  strBEURL + '/assertions/'  + strParams)
      .then(res => {
if (res.data.message) {
  if (document.getElementById("messagePlace") ){
    document.getElementById("messagePlace").innerHTML =  ' Not Found: ' + res.data
  }
}

        this.setState({
          assertions: res.data
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          status: JSON.stringify(error)
        });

      })
  }


addAssertion() {

  axios
    .post(
      'https://' +  strBEURL + '/assertions/create-assertion/?token=' + localStorage.getItem('USER_MODEL_TOKEN')       ,
    )
    .then((res) => {

      if (document.getElementById("messagePlace") ){
        document.getElementById("messagePlace").innerHTML =  ' OK: ' + res.data
    }

    })
    .catch((error) => {
      console.log(error)
      if (document.getElementById("messagePlace") ){
        document.getElementById("messagePlace").innerHTML =  error
      }
    })
}

  DataTable() {
    return this.state.assertions.map((res, i) => {
      return <AssertionTableRow obj={res} key={i} />;
    });
  }
  render() {
   
    return (
    <div>
      <p align="right">
      <Link
        className="nav-link" path={"/:KNID"}     //    className="edit-link" path={"assertion/:id"}
        to={'/explain/' + 'KN3'}   // hardcoded so when it comes off the Nav bar it has a place to open - ie intro for all  ---  when hitting explain from a particular panel the buton will pass the panel id
        >
        Explain&#62;
      </Link>
      </p>
      <p><font size="5" > <b><i>Assertionizer Model</i></b>  </font></p>

      <div className="table-wrapper">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>SpecID </th>
              <th>Assertion Name</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>

            {this.DataTable()}
          </tbody>
        </Table>
      </div>

      <Button onClick={this.addAssertion} size="sm" variant="danger">
          Add New Assertion
      </Button>

      <div id="messagePlace" class="alert alert-primary" role="alert">
          Assertions ..
        </div>

    </div> 

    );

  }
}