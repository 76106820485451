import React, { Component } from 'react'
import {  Form } from "react-bootstrap";
import axios from 'axios'
import Button from 'react-bootstrap/Button'

const strBEURL = 'clammytest.assertions.ca'   // back end url for prod  BE

const showFeedback = ((feedback,place) => {
  if (document.getElementById(place) ){
    document.getElementById(place).innerHTML = feedback
  }
})

export default class InputConnectionTableRow extends Component {
  constructor(props) {
    super(props)
    
    this.deleteConnection = this.deleteConnection.bind(this)
    this.updateConnection = this.updateConnection.bind(this)

    this.onChangeTransmissionType = this.onChangeTransmissionType.bind(this)
    this.onChangeTransmissionNote = this.onChangeTransmissionNote.bind(this);
    this.onChangePushPull = this.onChangePushPull.bind(this);
  }

  componentDidMount() {
    this.setState({
      cGUID: this.props.obj.ConnectorGUID,
      TransmissionType: this.props.obj.TransmissionType ,
      TransmissionNote: this.props.obj.TransmissionNote ,
      PushPull: this.props.obj.PushPull ,
    })
  }

  onChangeTransmissionType(e) {this.setState({TransmissionType: e.target.value })}  // thses set value for the state that is subsequenlty passed to BE for update
  onChangeTransmissionNote(e) {this.setState({ TransmissionNote: e.target.value })}
  onChangePushPull(e) {this.setState({PushPull: e.target.value })}

  deleteConnection() {   
    let request =   'http://' +  strBEURL + '/connections/delete/?cGUID=' + this.props.obj.ConnectorGUID
    axios
      .get(
        request
      )
      .then((delres) => {
        showFeedback ('RESULT: ' + delres.data,"messagePlace")
      //                                                                                                                   #### delete should also update the state so it disappears
      })
      .catch((ERROR) => {
        showFeedback ('Connection NOT deleted! ERROR: ' + ERROR ,"messagePlace")
      })

      this.props.HandleConnectionDelete()  // call the parents fetch to refill InputConnections
  }

updateConnection(){
    let strParam = "cGUID=" + this.state.cGUID  +"&TransmissionType=" + this.state.TransmissionType  + "&TransmissionNote=" + this.state.TransmissionNote    + "&PushPull=" + this.state.PushPull
    
    axios.get('https://' +  strBEURL + '/connections/update-connection/?'  + strParam)
    .then((res) => {
      showFeedback (" UPDATED Connection:  " + res.data);  
    })
    .catch(function (error) {
      showFeedback ("Error: " + error + '\n Possible access control. Ensure chrome allows cross domain. RUN: chrome.exe --user-data-dir="C:/Chrome dev session" --disable-web-security'); 
    })
  }

  render() {
    return (
      <tr>

<td><a href={'/edit-assertion/' + this.props.obj.sourceAutoID}><b><font color="navy"> {this.props.obj.SourceSpecID} - {this.props.obj.SourceSpecName}</font></b></a></td>
     
        <td>
          <Form.Group controlId="TransmissionType_"  >
            <Form.Select   type="text" defaultValue={this.props.obj.TransmissionType } onChange={this.onChangeTransmissionType}>
              <option value="DIRECT">DIRECT</option>
              <option value="CONTENTSTORE">CONTENTSTORE</option>
              <option value="DBSTORE">DBSTORE</option>
              <option value="EMAIL">EMAIL</option>
              <option value="VERBAL">VERBAL</option>
              <option value="COMMUNICATION">COMMUNICATION</option>
              <option value="POSTAL">DIRECT</option>
              <option value="tbd">tbd</option>
            </Form.Select>
          </Form.Group>
        </td>
        <td>
        <Form.Group controlId="PushPull_"  >
            <Form.Select   type="text" defaultValue={this.props.obj.PushPull }  onChange={this.onChangePushPull}>
              <option value="PUSH">PUSH</option>
              <option value="PULL">PULL</option>
            </Form.Select>
          </Form.Group>
        </td>
        <td>
        <Form.Group controlId="TransmissionNote_"  >
            <Form.Control type="text" defaultValue={this.props.obj.TransmissionNote}  onChange={this.onChangeTransmissionNote}/>
          </Form.Group>
        </td>
        <td>
          <Button  onClick={this.updateConnection} size="sm" variant="warning" >
            Update
          </Button>
          <Button onClick={this.deleteConnection} size="sm" variant="danger">
            Delete
          </Button>
    
        </td>
       
      </tr>
    )
  }
}
