import React, { Component } from "react";
import {jsPlumb} from 'jsplumb';


export default class Canvas extends Component {

  constructor(props) {
    super(props)
    this.addBox = this.addBox.bind(this)
  }

  componentDidMount() {
    this.setState({
      fnHandler: this.props.hdlrSelectAssn,
    })
    jsPlumb.ready(function () { 
      var common = {
        isSource: true,
        isTarget: true,
        connector: "Straight",
        endpoint: "Rectangle",
        paintStyle: { fill: "white", outlineStroke: "blue", strokeWidth: 2 },
        hoverPaintStyle: { outlineStroke: "lightblue" },
        connectorStyle: { outlineStroke: "green", strokeWidth: 1 },
        connectorHoverStyle: { strokeWidth: 2 },
        Container: "canvasContainer",
      };
    jsPlumb.setContainer(document.getElementById("canvasContainer"));         
  });
};






//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
render() {     //  render is  a method of this Canvas Class !

  const addMyBox= (boxID, x, y, w, h) => {   // returns the element
    const el = document.createElement('div')
    el.id = 'box_' + boxID
    el.className = "rl-canvas-box"   // in CSS  - not there yetcanvas
    el.style.position = 'absolute'
    // thise are top left posn  rl-canvas-box defines size
    el.style.left = `${x}px`
    el.style.top = `${y}px`
    el.style.height = `${h}px`
    el.style.width = `${w}px`
    //   transparaent how -- el.style.backgroundColor = obStyle.backgroundColor
    return (el)
    };





  console.log('Rendering Canvas')
  let myHandler = this.props.hdlrSelectAssn  // myHandler is local to to the render method  - hdlrSelectAssn is passed down
  function elementClicked2(selEl) {
    myHandler(selEl)
  }

  let srcdbid
  let tardbid
  //jsPlumb.empty("canvasContainer");
  let cancon = document.getElementById("canvasContainer")
  if (!cancon){
    cancon = document.createElement("canvasContainer");
    jsPlumb.setContainer(cancon);  
    cancon.style.width = '400px'   // does not work
    cancon.style.height = '200px'
  } else {
    jsPlumb.empty("canvasContainer");
  }

  let dictElements = {}  //  to support connectors finding plumb elements to connect
    
  if (this.props.diagram){    // ensure diagram
    let elDivThis; 
    if (this.props.diagram.Name) {  // put name on diagram
      //console.log(this.props.diagram.Name)
      const el2 = document.createElement('div')
      el2.id = 'dgmname_' + this.props.diagram.Name
      el2.className = "rl_item"
      el2.style ={color: 'red',backgroundColor: '#F2CDA5', borderStyle: 'dotted'  }  // nope not work
      //el2.style.borderStyle= "dotted"
      //el2.style.borderWidth = "thick"
      jsPlumb.draggable(el2)
      const labelEl2 = document.createElement('div')
      labelEl2.innerText = this.props.diagram.Name 
      el2.appendChild(labelEl2)
      // jsPlumb uses absolute positioning
      el2.style.position = 'absolute'
      el2.style.left = `${1000}px`
      el2.style.top = `${5}px` 
      jsPlumb.getContainer().appendChild(el2)   //this work  but is it right container
      cancon.appendChild(el2)       // I thik this is same as above
    }

    let canvasRect = cancon.getBoundingClientRect();   // the working canvas

    if (this.props.diagram.diagramobjects) {   // Add the objects
   
      let xValues = this.props.diagram.diagramobjects.map((element) => {return element.XPos})   // get the extents from stored values
      let yValues = this.props.diagram.diagramobjects.map((element) => {return element.YPos})
      let xWidth= Math.max(...xValues) - Math.min(...xValues)   
      let yHeight= Math.max(...yValues) - Math.min(...yValues)    
      let elemWidth = 150   //these show in the css.  Use to scale so elements fit
      let elemHeight = 70 
      let xScale = (canvasRect.width - elemWidth) / xWidth /1.05   // bit smaller thatn the canvas
      let yScale = (canvasRect.height - elemHeight) / yHeight /1.05

      let ScrollX = window.scrollX?window.scrollX:0
      let ScrollY = window.scrollY?window.scrollY:0
      let xDisplayOrigin = canvasRect.left + ScrollX
      let yDisplayOrigin = canvasRect.top + ScrollY

      console.log('scale: : (' + xScale + ',' + yScale + ')' +  '  Canvas leftop: : (' + canvasRect.left  + ',' + canvasRect.top  + ')'   +  '  Scroll xy : (' + ScrollX + ',' + ScrollY + ')' )

      this.props.diagram.diagramobjects.map((element) => {      // cycle the elements
        // scale the stored values to the px values
     
        let x =  xScale * (parseFloat(element.XPos) - Math.min(...xValues)) //+ xDisplayOrigin //  Math.min(...xValues) is the dataset origin // ie adjust to dataset origin, scale it, adjust display origin
        let y =  yScale * (parseFloat(element.YPos)- Math.min(...yValues)) //+   yDisplayOrigin
        console.log(element.Name + ' (' + element.XPos + ',' + element.YPos + ') moved to (' + x + ',' + y + ')')
        let style = this.getLegendStyle('BAG', element)
        elDivThis = this.addElement(x, y, element.dbid, element.Name, element.AuthName , style, element.ModelLevel);   // this will have to build a dictinary to use for connectors
        //elDivThis.addEventListener("mousedown", function() {elementClicked2(element.dbid)}        );  //  this works  -  try pasn
        let elSel = { dbid: element.dbid, asnName: element.SpecID + ' - ' + element.Name}
        elDivThis.addEventListener('contextmenu', (ev) => {ev.preventDefault(); elementClicked2(elSel)});  //  right click
        dictElements[element.strAssnGUID] = 'dgm_' + element.dbid;  // build dict for connectors   // may not be necessary
        return elDivThis
      })        
    }
    // now the dict is ready to feed the connectors
    if (this.props.connections) {   // Add the Connectors
      this.props.connections.map((connector) => {
        srcdbid =  dictElements[connector.SourceSpecGUID]
        tardbid =  dictElements[connector.TargetSpecGUID]
        if  (srcdbid && tardbid ){  //  ensure there
          try {
            this.addConnector(srcdbid , tardbid ); // connector knows spec guodes of strt and end  -- dict knows matching diagram ID DID
          }catch(error) {
            console.log(error)
          }
          this.addConnector(srcdbid , tardbid ); 
        }
        return connector
      })
  }
  }

  // now try addbox
 
  if (this.props.diagram.ExtraElements) { 
    let arrExtraElements = JSON.parse(this.props.diagram.ExtraElements)
    console.log(arrExtraElements)
    //let exelem = arrExtraElements[0]
    //let exBox = this.addBox(exelem.Name ,exelem.X, exelem.Y,exelem.Width,exelem.Height)
    //cancon.appendChild(exBox)
    //jsPlumb.draggable(exBox)
     /* HIDE  HIDE */
     let exBox
    arrExtraElements.forEach(function(exelem) {
      exBox = addMyBox(exelem.Name ,exelem.X, exelem.Y,exelem.Width,exelem.Height)
      cancon.appendChild(exBox)
      jsPlumb.draggable(exBox)
    })
     
  }



 let elBox = this.addBox('B1', 0, 0,0,0)
  cancon.appendChild(elBox)
  jsPlumb.draggable(elBox)

  jsPlumb.repaintEverything(); // does not seem to do much

  return (
    <div className= "cmpCanvas"     >
      <div id="canvasContainer" />
    </div>
  );
  }
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  addConnector= (conSourceDID,conTargetDID) => {
    var common = {
      connector: ["Straight", { cornerRadius: 10 }],
      endpoint: ["Dot", { radius: 10 }],
      anchors: ["Right", "Left"],
      connectorOverlays: [
      ["Arrow", { width: 10, length: 15, location: 0.5, id: "arrow" }]
      ]
    };
    //console.log('Adding Connector: ' + conSourceDID + ' ' + conTargetDID)
    jsPlumb.connect({ 
      source: conSourceDID, 
      target: conTargetDID,  
      connector: ["Flowchart", { stub: 14, cornerRadius:10 }],   // is cornerRadius a toolkit capability only?? No  // stub is how many pixels out tom element to go before corner
      overlays: 
        [["Arrow", { width: 10, length: 15, location: 0.55, id: "arrow" }]],   
      endpoint: ["Dot", { radius: 5 }],
      anchors: ["Bottom", "Left"],   // ie input and output
    });
  }





addBox= (boxID, x, y, w, h) => {   // returns the element
	const el = document.createElement('div')
	el.id = 'box_' + boxID
	el.className = "rl-canvas-box"   // in CSS  - not there yetcanvas
	el.style.position = 'absolute'
  // thise are top left posn  rl-canvas-box defines size
	el.style.left = `${x}px`
	el.style.top = `${y}px`
  el.style.height = `${h}px`
  el.style.width = `${w}px`
	//   transparaent how -- el.style.backgroundColor = obStyle.backgroundColor
	return (el)
	};

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++






  addElement= (x, y, id, name, auth, obStyle, modLevel) => {
    const el = document.createElement('div')
    // jsPlumb comes with a handy ID generator
    el.id = 'dgm_' + id
    el.className = "rl-canvas-element"   // in CSS

// tuck in border style
switch (modLevel) {
  case 'EXTERNAL':


    el.style.borderStyle= "dashed"
    el.style.borderWidth = "medium"

  break;
  case 'GOVERNANCE':
    el.style.borderStyle= "dotted"
    el.style.borderWidth = "medium"
  break;
}



    jsPlumb.draggable(el, {
      containment:true
   })

    // this part is optional, it just creates a text label
    const labelEl = document.createElement('div')
    labelEl.style = 'color: green; text-align: right'
    labelEl.innerText = auth 
    el.appendChild(labelEl)
    const labelEl2 = document.createElement('div')
    labelEl2.innerText = name
    el.appendChild(labelEl2)
    // jsPlumb uses absolute positioning
    el.style.position = 'absolute'
    el.style.left = `${x}px`
    el.style.top = `${y}px`
    el.style.backgroundColor = obStyle.backgroundColor
    //el.style.cssText = style
    //jsPlumb.getContainer().positionElementAt(el,x,y,0,0,true)    // tried to see if position Element At works  NOPE
    jsPlumb.getContainer().appendChild(el)
    jsPlumb.makeSource(el, { filter: '.handle' })
    jsPlumb.makeTarget(el, { allowLoopback: false }) // allowLoopback refers to connections with the same source and target

    return (el)
  };

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

getLegendStyle = (legendID, element) => {
// return the style to use for this legend ID
//The style is then passed to add element
// Nots as of 240427 -- A legend has a set of style types (eg bgcolor) each styletype has a spec for returning the value -- ie bgcolr (element.Model level ) ie what to use as arg and then how to calc


  let obStyle  = 'huh' // the string to be returned
  switch (legendID) {
    case 'BAG':                             // for BArch Gild
      switch (element.ActivityType) {
        case 'capability':
          obStyle = {backgroundColor: '#89C4FF', borderStyle: 'dotted'  }  
        break;
        case 'value stream':
          obStyle =  {backgroundColor: 'tan', borderStyle: 'dotted',  borderRadius: 1}
        break;
        case 'product':
          obStyle = {backgroundColor: '#F2CDA5', borderStyle: 'dotted'  }
        break;
        case 'outcome':
          obStyle = {backgroundColor: '#89C4FF', borderStyle: 'dotted'  }  
        break;
        case 'value proposition':
          obStyle =  {backgroundColor: 'tan', borderStyle: 'dotted'  }
        break;
        case 'value item':
          obStyle = {backgroundColor: 'tan', borderStyle: 'dotted'  }
        break;
      }
      break;
    
    case 'lgdOTHER':
      // another legend here
      break;

      default:
        obStyle = {backgroundColor: 'white', borderStyle: 'dotted'}
  }

  return obStyle
}


}